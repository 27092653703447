import React from "react"
import { Link } from "gatsby"

import Layout from "../modules/layout"
import Seo from "../components/Seo"
import Projects from "../modules/projects"

const PageIndex = () => (
  <Layout>
    <Seo
      title="Home"
      description="Dom Smith, a web developer. I build predominantly using Wordpress and obsess over visuals and user experience."
    />
    <section>
      <h3>I am...</h3>
      <ul className="pilled">
        <li>Oxfordshire based</li>
        <li>Full stack</li>
        <li>HTML, CSS/SCSS, JS, &amp; PHP</li>
        <li><a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer" aria-label="Learn more about React">React</a></li>
        <li><a href="https://nodejs.org/en/" target="_blank" rel="noopener noreferrer" aria-label="Learn more about Node.js">Node.js</a></li>
        <li><a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer" aria-label="Learn more about Gatsby">Gatsby</a></li>
        <li><a href="https://wordpress.com/" target="_blank" rel="noopener noreferrer" aria-label="Learn more about WordPress">WordPress</a>&nbsp;&amp;&nbsp;<a href="https://woocommerce.com/" target="_blank" rel="noopener noreferrer" aria-label="Learn more about WooCommerce">WooCommerce</a></li>
      </ul>
    </section>
    <section>
      <div className="section-title-bar">
        <h3>My latest work...</h3>
        <Link to="/work">View all work</Link>
      </div>
      <Projects limit={3} />
      <div className="text-center">
        <Link to="/work" className="centered">View all work</Link>
      </div>
    </section>
  </Layout>
)

export default PageIndex;
